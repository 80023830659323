import React, { Fragment }  from "react";
import LogoblancoImg from "../images/newlogoLCSblanco.svg";


const navigation = {
    solutions: [
      { name: 'Recetas', href: '/recetas/' },
      { name: 'Trucos de cocina', href: '/trucos-de-cocina/' },
      { name: 'Consejos de salud', href: '/consejos-de-salud/' },
      { name: 'Noticias', href: '/cocina-sana/' },
    ],
    support: [
      { name: 'Contacto', href: 'mailto:paola@lacocinasana.com' },
    ],
    company: [
      { name: 'Quienes somos', href: '/cocina-sana/la-cocina-sana-quienes-somos' },
      { name: 'Club Cocina Sana', href: 'https://lacocinasana.com/clubcocinasana' },
    ],
    legal: [
      { name: 'Aviso legal', href: '/politica-de-privacidad' },
      { name: 'Política de cookies', href: '/politica-de-privacidad' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/lacocinasanaoficial',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/paola_cocinasana/',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Pinterest',
        href: 'https://www.pinterest.es/lacocinasanaoficial',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Telegram',
        href: 'https://t.me/lacocinasana',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M21 5L2 12.5L9 13.5M21 5L18.5 20L9 13.5M21 5L9 13.5M9 13.5V19L12.2488 15.7229" />
          </svg>
        ),
      },
    ],
  }
  
  export default function Footer() {
    return (
      <footer className="bg-amarillo text-black " aria-labelledby="footer-heading">
        <div className="max-w-7xl mx-auto pt-8 px-4 sm:px-6 lg:pt-12 pb-5 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-6">
            <div className="space-y-5 xl:col-span-1">
              <img
                className="w-24"
                src={LogoblancoImg}
                alt="La Cocina Sana"
              />
              <p className="text-black text-3xl circularbold w-56 ">
              LA SALUD EMPIEZA EN LA COCINA
              </p>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-6 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-6">
                <div>
                  <h3 className="circularbold tracking-wider uppercase ">Secciones</h3>
                  <ul role="list" className="mt-5 space-y-2 list-none">
                    {navigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-black hover:text-gray-900 ">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="circularbold tracking-wider uppercase ">Contacto</h3>
                  <ul role="list" className="mt-5 space-y-2 list-none">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-black hover:text-gray-900 ">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-6 ">
                <div>
                  <h3 className="circularbold tracking-wider uppercase ">LCS</h3>
                  <ul role="list" className="mt-5 space-y-2 list-none">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-black hover:text-gray-900 ">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="circularbold tracking-wider uppercase ">Legal</h3>
                  <ul role="list" className="mt-5 space-y-2 list-none">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-black hover:text-gray-900 ">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 mt-8 border-t border-gray-900 pt-6  w-full">
            <div>
              <p className="text-base text-black text-center md:text-left">&copy; 2023 - LA COCINA SANA</p>
              <p className="text-xxs text-black text-center md:text-left">LA COCINA SANA es una marca registrada</p>
            </div>
            <div className="text-s14 text-black text-center md:text-left">¿Quieres una web como la de La Cocina Sana?. <a href="#mailgo" data-address="info" data-domain="redcobot.com" className="underline"
  >Contacto</a></div>
            <div className="grid grid-cols-4 gap-4 place-items-center pt-5 md:place-items-right md:pt-0 md:flex md:justify-end  pb-32 md:pb-4">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-black hover:text-black self-center ">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
          </div>
        </div>
      </footer>
    )
  }