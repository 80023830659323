import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Seo from "./seo";
import Cabecera from "./cabecera";
import Footer from "./footer";

const Layout = ({ children, seo }) => (
  <StaticQuery
    query={graphql`
      query {
        strapiHomepage {
          seo {
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div>
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <Seo seo={seo} />
        <Cabecera />
        </div>
      <main>{children}</main>
      <Footer />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
