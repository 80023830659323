import React, { Fragment }  from "react";
import { Popover, Transition } from '@headlessui/react';
import {
  ChartBarIcon,
  CursorClickIcon,
  DocumentReportIcon,
  MenuIcon,
  RefreshIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import LogoImg from "../images/newlogoLCS.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Cabecera() {
  return (
    <Popover className="relative bg-white z-50">
      <img
          className="hidden"
          src="https://ct.pinterest.com/v3/?event=init&tid=2613435780576&noscript=1"
          alt="Recetas de cocina sanas"
        />
      <div className="flex justify-between items-center px-4 py-2 md:py-4 sm:px-6 md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1 w-24">
          <a href="/">
            <span className="sr-only"></span>
            <img
              className="w-20 md:w-24"
              src={LogoImg}
              alt="Recetas de comida saludable"
            />
          </a>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-morado">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden md:flex space-x-5">
          <a href="/recetas" className="text-base font-bold text-black hover:text-gray-500 ">
            RECETAS
          </a>
          <a href="/reels" className="text-base font-bold text-black hover:text-gray-500 ">
            REELS
          </a>
          <a href="/trucos-de-cocina" className="text-base font-bold text-black hover:text-gray-500 ">
            TRUCOS DE COCINA
          </a>
          <a href="/consejos-de-salud" className="text-base font-bold text-black hover:text-gray-500 ">
            CONSEJOS DE SALUD
          </a>
          <a href="/cocina-sana" className="text-base font-bold text-black hover:text-gray-500 ">
            NOTICIAS
          </a>
          <a href="https://lacocinasana.com/clubcocinasana/tienda-de-la-cocina-sana/" target="_blank" className="text-base font-bold text-black hover:text-gray-500 ">
            PROGRAMAS
          </a>
        </Popover.Group>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <a
            href="https://lacocinasana.com/clubcocinasana" target="_blank"
            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-morado hover:bg-moradoclaro"
          >
            ADELGAZA CON SALUD
          </a>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden bg-white">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-4">
              <div className="flex items-center justify-between">
                <div>
                <img
                  className="w-20"
                  src={LogoImg}
                  alt="Recetas de comida saludable"
                />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-morado">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>

            </div>
            <div className="py-6 px-5">
              <div className="grid grid-cols-1 text-center gap-4">
              <a href="/recetas" className="text-base font-bold text-black hover:text-gray-500 ">
                RECETAS
              </a>
              <a href="/reels" className="text-base font-bold text-black hover:text-gray-500 ">
                REELS
              </a>
              <a href="/trucos-de-cocina" className="text-base font-bold text-black hover:text-gray-500 ">
                TRUCOS DE COCINA
              </a>
              <a href="/consejos-de-salud" className="text-base font-bold text-black hover:text-gray-500 ">
                CONSEJOS DE SALUD
              </a>
              <a href="/cocina-sana" className="text-base font-bold text-black hover:text-gray-500 ">
                NOTICIAS
              </a>
              <a href="https://lacocinasana.com/clubcocinasana/tienda-de-la-cocina-sana/" target="_blank" className="text-base font-bold text-black hover:text-gray-500 ">
                PROGRAMAS
              </a>
              </div>
              <div className="mt-6">
                <a
                  href="https://lacocinasana.com/clubcocinasana/" target="_blank"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-bold text-white bg-morado hover:bg-moradoclaro"
                >
                  ADELGAZA CON SALUD
                </a>
                
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
